<template>
  <div class="section qycust-detail-model-box">
    <div class="header-box">
      <qy-header :data="custHeaderInfo" />
    </div>
    <tab-composite
      ref="tabComposite"
      class="main"
      fixed-height="192"
      :tab-list="tabList"
      :load-enable="loadEnable"
      :refresh-enable="loadEnable"
      :need-btn="needBtn"
      :need-border="true"
      @getIndex="getIndex"
    >
      <ul
        slot="1"
        class="list-wrapper outing"
      >
        <div
          v-for="(item, indexs) of businessList"
          :key="indexs"
          class="out-box"
        >
          <business-info :data="item.mainObj" />
        </div>
        <error-page v-if="!businessList.length" />
      </ul>
      <ul
        slot="2"
        class="list-wrapper outing"
      >
        <div
          v-for="(item, indexs) of followList"
          :key="indexs"
          class="out-box"
        >
          <followInfo
            :data="item.mainObj"
            :index="indexs"
          />
        </div>
        <error-page v-if="!followList.length" />
      </ul>
      <ul
        slot="3"
        class="list-wrapper"
      >
        <basic-info ref="basicInfo" />
      </ul>
      <ul
        slot="4"
        class="list-wrapper outing"
      >
        <div
          v-for="(item, indexs) of custContactsList"
          :key="indexs"
          class="out-box"
        >
          <contactPerson :data="item.mainObj" />
        </div>
        <error-page v-if="!custContactsList.length" />
      </ul>
    </tab-composite>
    <div
      class="bottom-postion"
    >
      <div
        v-if="showBtn"
        v-click-outside="onClickOutside"
        class="pop-up-layer"
      >
        <div
          class="pop-up-btn"
          @click="toAssociate"
        >
          关联其他企业
        </div>
        <div
          class="pop-up-btn"
          @click="toUntie"
        >
          解绑企业
        </div>
      </div>
      <div
        class="btn-more"
        @click="showBtn = true"
      >
        <i class="icon-joyo arrows">&#xe6b2;</i>
        <div>更多</div>
      </div>
      <cube-button
        class="btn-div"
        @click="addBusiness"
      >
        新增商机
      </cube-button>
      <cube-button
        class="btn-divs"
        @click="addFollow"
      >
        添加跟进
      </cube-button>
    </div>
  </div>
</template>

<script>
import '@/directive/clickOutside.js';
import qyHeader from '_c/cust-header/qyheader';
import tabComposite from '_c/tab-composite';
import businessInfo from '@/views/qyCust/components/businessInfo';
import followInfo from '@/views/qyCust/components/followInfo';
import basicInfo from '@/views/qyCust/components/basicInfo';
import contactPerson from '@/views/qyCust/components/contactPerson';
import errorPage from '_c/errorPage';
import { getWxCustDetail, unbindRelation } from '_api/qycust';
import { findValueByKey } from '@/lib/until.js';
import { mapGetters } from 'vuex';
export default {
  name: 'QycustDetail',
  components: {
    qyHeader,
    tabComposite,
    businessInfo,
    followInfo,
    basicInfo,
    contactPerson,
    errorPage
  },
  data() {
    return {
      showBtn: false,
      custHeaderInfo: {}, // 头部信息
      businessList: [],
      followList: [],
      basicInfo: {},
      custContactsList: [],
      tabList: [{
        label: '商机',
        value: 0
      }, {
        label: '跟进信息',
        value: 1
      }, {
        label: '基本信息',
        value: 2
      }, {
        label: '联系人',
        value: 3
      }],
      index: 0,
      loadEnable: false,
      needBtn: true,
    };
  },
  computed: {
    ...mapGetters(['externalUserid', 'dictList', 'system'])
  },
  mounted() {
    document.querySelector('.qycust-detail-model-box').addEventListener('touchmove', function(e) {
      e.preventDefault(); // 阻止默认的处理方式(阻止下拉滑动的效果)
    }, {passive: false});
    this.getDetails();
  },
  methods: {
    getDetails() {
      getWxCustDetail(this.externalUserid).then(res => {
        if (res.flag) {
          this.tabList[0].label = `商机(${res.data.oppoInfoWxViewList.length})`;
          this.tabList[3].label = `联系人(${res.data.custContactsList.length})`;
          // 头部信息;
          let custHeaderInfo = res.data.custView;
          this.custHeaderInfo = {
            title: custHeaderInfo.custCn,
            statusName: findValueByKey(this.dictList.custStatus, custHeaderInfo.custStatus),
            mainList: [
              `企业规模：${findValueByKey(this.dictList.scale, custHeaderInfo.scale)}`,
              `客户来源：${findValueByKey(this.dictList.clueSource, custHeaderInfo.source)}`,
              `行业类型：${custHeaderInfo.custInd ? custHeaderInfo.custInd : ''}`
            ]
          };
          // 商机信息
          this.businessList = res.data.oppoInfoWxViewList;
          this.businessList.forEach(item => {
            item.mainObj = {
              oppoLevel: findValueByKey(this.dictList.oppoLevel, item.oppoLevel) + '级',
              typeTitle: `${item.busTypeName}-${item.comboTitle}`,
              oppoCode: item.oppoCode,
              status: findValueByKey(this.dictList.oppoStatus, item.oppoStatus),
              trackReliable: item.trackReliable,
              trackReliableName: findValueByKey(this.dictList.reliable, item.trackReliable),
            };
          });
          // 跟进信息
          this.followList = res.data.trackInfoWxViewList;
          this.followList.forEach(item => {
            item.mainObj = {
              contactName: item.contactName || '',
              title: `${item.contactName ? item.contactName : ''} - ${item.contactPosition ? item.contactPosition : ''}`,
              trackDate: item.trackDate,
              trackType: findValueByKey(this.dictList.trackType, item.trackType),
              content: `商机产品：${item.busTypeName}-${item.comboTitle}</br>
                        跟进内容：${item.trackRemark}</br>
                        进程推进：${findValueByKey(this.dictList.reliable, item.trackReliable)}</br>
                        ${item.accompanyNames !== null && item.accompanyNames !== 'null' ? '陪访人员：' + item.accompanyNames + '</br>' : ''}
                        下次跟进日期：${item.nextTrackTime}</br>
                        ${item.visitPurpose ? '跟进目的：' + item.visitPurpose : ''}`
            };
          });

          // 基本信息
          this.basicInfo = res.data.custView;
          if (this.$refs.basicInfo) this.$refs.basicInfo.getData(res.data.custView);

          // 联系人信息
          this.custContactsList = res.data.custContactsList;
          this.custContactsList.forEach(item => {
            item.mainObj = {
              title: item.name ? item.name : '',
              mobilePosition: `${item.mobile ? item.mobile : item.tel ? item.tel : ''} - ${item.position ? item.position : ''}`
            };
          });
        } else {
          this.$showToast(res.errorMsg);
        }
      });
    },
    getIndex() {

    },
    onClickOutside() {
      this.showBtn = !this.showBtn;
    },
    // 解绑企业操作弹窗
    toUntie() {
      this.showBtn = false;
      this.$createDialog(
        {
          type: 'confirm',
          icon: 'cubeic-alert',
          title: '确定解绑当前企业',
          confirmBtn: {
            text: '确定',
            active: true,
            disabled: false,
            href: 'javascript:;'
          },
          cancelBtn: {
            text: '取消',
            active: false,
            disabled: false,
            href: 'javascript:;'
          },
          onConfirm: () => {
            this.unbindRelationd();
          },
          onCancel: () => {

          }
        }).show();
    },
    // 解绑企业
    unbindRelationd() {
      unbindRelation(this.externalUserid, this.basicInfo.id).then(res => {
        if (res.flag) {
          this.$showToast('企业解绑成功');
          if (res.data.custView !== null) {
            this.getDetails();
          } else {
            this.toAssociateErr();
          }
        } else {
          this.$showToast(res.errorMsg);
        }
      });
    },
    toAssociateErr() {
      this.$router.push(
        {
          name: 'AssociatedError'
        }
      );
    },
    toAssociate() {
      this.$router.push(
        {
          name: 'AssociatedCompanies'
        }
      );
    },
    addBusiness() {
      this.$router.push(
        {
          name: 'AddeditBusiness',
          query: {
            custId: this.basicInfo.id,
            custCn: this.basicInfo.custCn,
            custStatus: this.basicInfo.custStatus,
            from: 'qyWeChat'
          }
        }
      );
    },
    addFollow() {
      this.$router.push(
        {
          name: 'AddeditFollow',
          query: {
            custId: this.basicInfo.id,
            custCn: this.basicInfo.custCn,
            from: 'fromCust',
            source: 'qyWeChat'
          }
        }
      );
    }
  }
};
</script>
<style lang="scss">
.qycust-detail-model-box{
  .cube-scroll-wrapper{
    overflow: scroll;
  }
}
</style>
<style  lang="scss"  scoped>
.section{
  background-color:$color-FFF ;
}
.bottom-postion{
  position: absolute;
  bottom: 15px;
  z-index: 10;
  display: flex;
  justify-content: space-around;
  width:100%;
  height: 40px;
  padding-top: 10px;
  border-top: 1px solid #EAEBED ;
  .btn-div{
    width: 30%;
    color:$color-theme;
    line-height: 10px;
    background-color:$color-FFF;
    border: 1px solid $color-theme;
  }
  .btn-divs{
    width: 40%;
    margin: 0 15px 0 12px;
    color: $color-FFF;
    line-height: 10px;
    background-color:$color-theme;
  }
  .btn-more {
    flex: 1;
    margin-top: 4px;
    color: #23252E;
    font-size: $font-12;
    line-height: 16px;
    .arrows{
      display: inline-block;
      margin-bottom: 5px;
      font-size: 20px;
    }
  }
  .pop-up-layer{
    position: absolute;
    top: -118px;
    left: 15px;
    width: 128px;
    height: 112px;
    text-align: left;
    background: #FFF;
    border-radius: 8px;
    box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.16);
    .pop-up-btn{
      height: 56px;
      padding-left: 16px;
      color: #23252E;
      font-size: $font-16;
      line-height: 56px;
      border-bottom: 1px solid #EAEBED ;
    }
    .pop-up-btn:last-child{
      border-bottom: 0;
    }
  }
}

</style>
