<template>
  <div
    ref="cardBox"
    class="card-box"
  >
    <div class="card-cont">
      <div class="card-avatar">
        <div class="card-avatar-name">
          {{ interceptNames(data.title) }}
        </div>
      </div>
      <div class="card-content">
        <div class="card-title">
          {{ data.title }}
        </div>
        <div class="card-soure-time">
          <div class="card-time">
            {{ data.mobilePosition }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { interceptName } from '_lib/until';
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  methods: {
    interceptNames(val) {
      return interceptName(val);
    }
  }
};
</script>

<style lang="scss" scoped>
.card-box {
  width: 100%;
  overflow: hidden;
  background-color: $color-FFF;
  .card-cont {
    display: flex;
    justify-content: flex-start;
    margin: 16px 16px 0 16px;
    .card-avatar {
      margin-right: 12px;
      .card-avatar-name{
        width: 40px;
        height: 40px;
        color: #FFF;
        font-weight: 600;
        font-size: $font-12;
        line-height: 40px;
        text-align: center;
        background: linear-gradient(180deg, #B6CAD9 0%, #9BB7CC 100%);
        border-radius: 50%;
      }
    }
    .card-content {
      flex: 1;
      text-align: left;
      .card-title{
        margin-bottom: 4px;
        color: #23252E;
        font-size: $font-16;
        line-height: 20px;
      }
      .card-soure-time{
        display: flex;
        justify-content: flex-start;
        margin-bottom: 8px;
        .card-time{
          color:#999BA3;
          font-size: $font-14;
          line-height: 18px;
        }
      }
    }
  }
}
</style>
