<template>
  <div class="header-box">
    <div class="header-details">
      <div class="header-content">
        <div class="header-title">
          {{ data.title }}
        </div>
        <div class="header-state-box">
          <span
            class="header-state"
            :style="matchColors(data.statusName)"
          >{{ data.statusName }}</span>
        </div>
        <div
          v-for="(item , index) of data.mainList"
          :key="index"
        >
          <div class="header-span">
            {{ item }}
          </div>
        </div>
      </div>
      <div class="header-img">
        <img
          src="@/assets/img/company.png"
          alt=""
        >
      </div>
    </div>
  </div>
</template>

<script>
import { matchColor } from '_lib/until';
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {};
  },
  methods: {
    matchColors(val) {
      return matchColor(val);
    }
  }
};
</script>

<style lang="scss" scoped>
.header-box{
  width:100%;
  height: 192px;
  overflow: hidden;
  background: #F5F5F7;
  .header-details{
    display: flex;
    justify-content: space-between;
    height: 130px;
    margin: 15px 15px 15px 15px;
    padding: 16px;
    background: $color-FFF;
    .header-content{
      display: flex;
      flex:1;
      flex-direction: column;
      overflow: hidden;
      text-align: left;
      .header-title{
        margin-bottom: 8px;
        color: #23252E;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        @extend .one-ellipsis;
      }
      .header-state-box{
        margin-bottom: 16px;
        .header-state{
          padding: 3px 8px;
          color: #FF7B00;
          font-size: 14px;
          line-height: 18px;
          border-radius: 12px;
        }
      }
      .header-span{
        margin-bottom: 4px;
        color: #999BA3;
        font-size: $font-14;
        line-height: 18px;
        @extend .one-ellipsis;
      }
    }
    .header-img{
      width: 44px;
      img {
        width: 44px;
      }
    }
  }
}
</style>
