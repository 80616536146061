<template>
  <div class="cust-box">
    <div class="cust-content">
      <view-match :list="basicList" />
    </div>
  </div>
</template>

<script>
import viewMatch from '_c/view-match';
import { toArrays } from '@/lib/until.js';
import {mapGetters} from 'vuex';

export default {
  components: {
    viewMatch
  },
  data() {
    return {
      basicData: {
        custCn: {
          title: '企业名称',
          value: ''
        },
        custCreditCode: {
          title: '统一信用代码',
          value: ''
        },
        busStatus: {
          title: '经营状态',
          value: ''
        },
        legalPerson: {
          title: '法人代表',
          value: ''
        },
        establishDate: {
          title: '成立时间',
          value: ''
        },
        address: {
          title: '客户地址',
          value: ''
        },
        createStaff: {
          title: '创建人',
          value: ''
        },
        createTime: {
          title: '创建时间',
          value: ''
        }
      }
    };
  },
  computed: {
    basicList() { // 对象是引用类型，传递的是引用地址，所以两个数组引用的是同一个对象，只要其中一个数组改变，就会导致对象改变，进而另一个引用的数组也会改。
      return toArrays(this.basicData);
    },
    ...mapGetters(['dictList'])
  },
  methods: {
    getData(data) {
      // 基本信息
      for (let i in this.basicData) {
        this.basicData[i].value = data[i];
      }
      this.basicData.address.value = (data.addrProvinceName ? data.addrProvinceName : '') + (data.addrCityName ? data.addrCityName : '') + (data.addrAreaName ? data.addrAreaName : '') + (data.addrStreet ? data.addrStreet : '');
    }
  }
};
</script>

<style lang="scss" scoped>
.cust-box{
  width:100%;
  overflow: hidden;
  text-align: left;
  background-color: $color-FFF;
  .cust-content{
    margin: 22px 14px 0 14px;
  }
}

</style>
