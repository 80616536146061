<template>
  <div
    ref="cardBox"
    class="card-box"
  >
    <div class="card-line">
      <div
        :style="{backgroundColor: index === 0 ? '#fff' : '#EAEBED'}"
        class="card-line-online"
      />
      <div class="card-line-round" />
      <div class="card-line-offline" />
    </div>
    <div class="card-cont">
      <!-- <div class="card-avatar">
        <div class="card-avatar-name">
          {{ interceptNames(data.contactName) }}
        </div>
      </div> -->
      <div class="card-content">
        <!-- <div class="card-title">
          {{ data.title }}
        </div> -->
        <div class="card-soure-time">
          <div class="card-time">
            {{ data.trackDate }}
          </div>
          <div class="card-soure">
            {{ data.trackType }}
          </div>
        </div>
        <div class="card-detail">
          <div
            ref="cardCText"
            :class="{'card-detail-text' : true, 'twoline-ellipsis' : unfoldBool}"
            v-html="data.content"
          />
          <div
            class="card-unfold"
            @click="unfold"
          >
            <img
              :src="unfoldBool ? imgdown : imgup"
              alt=""
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { interceptName } from '_lib/until';
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      unfoldBool: true,
      imgup: require('../../../assets/img/up@2x.png'),
      imgdown: require('../../../assets/img/down@2x.png')
    };
  },
  methods: {
    unfold() {
      this.unfoldBool = !this.unfoldBool;
    },
    interceptNames(val) {
      return interceptName(val);
    }
  }
};
</script>

<style lang="scss" scoped>
.card-box {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  overflow: hidden;
  background-color: $color-FFF;
  .card-line{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 12px 0 16px;
    .card-line-online{
      width:1px;
      height: 24px;
      background-color: #EAEBED;
    }
    .card-line-round{
      width: 5px;
      height: 5px;
      background-color: #EAEBED;
      border-radius:50%;
    }
    .card-line-offline{
      flex: 1;
      width:1px;
      background-color: #EAEBED;
    }
  }
  .card-cont {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    margin: 16px 16px 0 0;
    .card-avatar {
      margin-right: 12px;
      .card-avatar-name{
        width: 40px;
        height: 40px;
        color: #FFF;
        font-weight: 600;
        font-size: $font-12;
        line-height: 42px;
        text-align: center;
        background: linear-gradient(180deg, #B6CAD9 0%, #9BB7CC 100%);
        border-radius: 50%;
      }
    }
    .card-content {
      flex: 1;
      margin-bottom: 16px;
      text-align: left;
      .card-title{
        margin-bottom: 4px;
        color: #23252E;
        font-size: $font-16;
        line-height: 20px;
      }
      .card-soure-time{
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
        .card-soure{
          max-width:180px;
          padding:1px 4px 1px 4px;
          color: $color-3FF;
          font-size:$font-12;
          line-height: 18px;
          background-color:$color-01;
          border-radius: 1px;
          @extend .uniline-ellipsis;
        }
        .card-time{
          color: #23252E;
          font-size: $font-16;
          line-height: 20px;
        }
      }
      .card-detail{
        position: relative;
        padding-right: 20px;
        .card-detail-text{
          flex-wrap: wrap;
          color: #999BA3;
          font-size: $font-14;
          line-height: 18px;
        }
        .twoline-ellipsis{
          display: -webkit-box;
          overflow : hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .card-unfold{
          position: absolute;
          right:0;
          bottom: 0;
          width: 100%;
          height: 16px;
          text-align: right;
          img{
            width:16px;
          }
        }
      }
    }
  }
}
</style>
