<template>
  <div class="card-box">
    <div class="card-content">
      <div class="card-cont">
        <div class="card-grade-title">
          <div class="card-grade">
            {{ data.oppoLevel }}
          </div>
          <div class="card-title">
            {{ data.typeTitle }}
          </div>
        </div>
        <div class="card-serialNumber">
          {{ data.oppoCode }}
        </div>
        <div class="card-schedule-reliable">
          <span class="icon-joyo card-arrows">{{ reliableMatch(data.trackReliable) }}</span>
          <div class="card-reliable">
            {{ data.trackReliableName }}
          </div>
        </div>
      </div>
      <div class="card-state">
        <i
          class="card-state-point"
          :style="matchColorBacks(data.status)"
        />
        <span class="card-state-text">{{ data.status }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { matchColorBack, reliableMatch } from '@/lib/until';
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  methods: {
    matchColorBacks(val) {
      return matchColorBack(val);
    },
    reliableMatch(val) {
      return reliableMatch(val);
    }
  }
};
</script>

<style lang="scss" scoped>
.card-box{
  width:100%;
  overflow: hidden;
  background-color: $color-FFF;
  .card-content{
    display: flex;
    justify-content: space-between;
    margin: 17px;
    .card-cont{
      flex: 1;
      text-align: left;
      .card-grade-title{
        display: flex;
        justify-content: flex-start;
        margin-bottom: 8px;
        .card-grade{
          width: 36px;
          height:20px;
          margin-right: 8px;
          color: #FFF;
          font-size: 12px;
          line-height: 22px;
          text-align: center;
          background-color: $color-theme;
          border-radius: 2px;
        }
        .card-title{
          color: #23252E;
          font-size: $font-16;
          line-height: 20px;
        }
      }
      .card-serialNumber{
        margin-bottom: 8px;
        color: #999BA3;
        font-size: $font-12;
        line-height: 16px;
      }
      .card-schedule-reliable{
        display: flex;
        justify-content: flex-start;
        .card-arrows{
          margin-right: 4px;
          color: #BFE8FF;
        }
        .card-reliable{
          color: #5A5C66;
          font-size: $font-12;
          line-height: 16px;
        }
      }
    }
    .card-state{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .card-state-point{
        width: 6px;
        height: 6px;
        margin-right: 4px;
        background: #FF7B00;
        border-radius: 50%;
      }
      .card-state-text{
        color: #23252E;
        font-size: $font-14;
        line-height: 18px;
      }
    }
  }
}
</style>
